import { useMemo } from "react";
import { ethers } from "ethers";

import ContractAbi from "../abi/contract.json";
import { chainIDToName } from "../utils/chain";

export default function useSaleContract() {
  const contract = useMemo(() => {
    const provider = new ethers.providers.InfuraProvider(
      chainIDToName[process.env.REACT_APP_CHAIN_ID],
      process.env.REACT_APP_INFURA_ID
    );
    const contractLocal = new ethers.Contract(
      process.env.REACT_APP_CONTRACT_ADDRESS,
      ContractAbi,
      provider
    );

    return contractLocal;
  }, []);

  return contract;
}
