import { useCallback, useEffect, useState } from "react";

export default function useFreeMintVariables(contract, account) {
  const [countMinted, setCountMinted] = useState(0);
  const [maxPerWallet, setMaxPerWallet] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [textError, setTextError] = useState("");

  const checkMint = useCallback(async () => {
    const mintsPerWallet = await contract.mintsPerWallet();
    setMaxPerWallet(mintsPerWallet);
    if (account) {
      const totalMintCount = await contract.totalMintCount(account);
      setCountMinted(totalMintCount);
      if (mintsPerWallet - totalMintCount === 0) {
        setTextError(
          `You have already minted the maximum (${mintsPerWallet} NFTS)`
        );
      }
    }
    const isActiveLocal = await contract.saleIsActive();
    setIsActive(isActiveLocal);

    if (!isActiveLocal) {
      setTextError("Sale is not active yet");
      return;
    }
  }, [contract, account]);

  useEffect(() => {
    checkMint();
  }, []);

  return {
    countMinted,
    maxPerWallet,
    isActive,
    textError,
    checkMint,
  };
}
