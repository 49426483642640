import { useCallback, useEffect, useState } from "react";
// import { TOTAL_SUPPLY } from "../constants/mint";

export default function useSupply(contractMint) {
  // TODO: Modifier 8000 par contractSale.maxSupplySale ou le mettre en dur ici mais qu'il soit pareil que maxSupplySale
  // const [totalSupply, setTotalSupply] = useState(TOTAL_SUPPLY);
  // const [totalSupplyRes, setTotalSupplyRes] = useState(TOTAL_SUPPLY);
  const [supplyMinted, setSupplyMinted] = useState(0);
  const [countMinted, setCountMinted] = useState(0);

  const onTransferEvent = useCallback(() => {
    console.log("Transfer event received");
    // setTotalSupplyRes((p) => p - 1);
    setSupplyMinted((p) => parseInt(p.toString(), 10) + 1);
  }, []);

  useEffect(() => {
    if (contractMint) {
      contractMint.off("Transfer", onTransferEvent);
      console.log("Initiating transfer event listener");
      contractMint.on("Transfer", onTransferEvent);
    }
    return () => {
      contractMint.off("Transfer", onTransferEvent);
    };
  }, [contractMint, onTransferEvent]);

  const updateTotalSupply = useCallback(async () => {
    // const totalSupply = await contractSale.maxSupplySale();
    const minted = await contractMint.totalSupply();
    /*setTotalSupplyRes(
      parseInt(totalSupply.toString(), 10) -
        parseInt(totalSupplyRes.toString(), 10)
    );*/
    // setTotalSupply(parseInt(totalSupply.toString(), 10));
    setSupplyMinted(parseInt(minted.toString(), 10));
  }, [contractMint]);

  return { updateTotalSupply, supplyMinted };
}
