import { Box, Typography, makeStyles } from "@material-ui/core";
import Logo from "../../../assets/logo.png";

import { ConnectButton } from "@rainbow-me/rainbowkit";
import React from "react";

const Terms = () => {
  const classes = useStyle();

  return (
    <Box className={classes.rooter}>
      <Box className={classes.header}>
        <ConnectButton />
      </Box>
      <Box className={classes.wrapperLogo}>
        <img alt="logo" src={Logo} />
      </Box>
      <Box className={classes.wrapperText}>
        <Typography variant="body1">
          PRIVACY POLICY Last Updated: 08/10/2023 1. Introduction Zion Punks
          values the privacy of its users ("you"). This policy outlines how we
          manage, use, and safeguard your information while using our services.
          2. Acceptance By using our services, you acknowledge and accept this
          Privacy Policy. The Privacy Policy is an integral part of our [Terms
          of Service](https://cryptoaidisrael.com/). 3. Data Collection We may
          collect: a) Non-personal data such as online activity. b) Transaction
          details, wallet addresses, and emails which might be considered
          personal data. Combined data will be treated as personal information.
          4. Cookies & Technologies We use cookies to improve user experience.
          These may include session, persistent, and third-party cookies.
          Disabling cookies may affect service functionality. 5. Usage of Data
          Your data assists in: a) Service provision and account setup. b)
          Responding to your queries. c) Preventing illicit activities and
          ensuring the integrity of our services. d) Legal and governmental
          compliance. 6. Data Sharing Your personal data isn't sold or rented.
          It might be shared with: a) Our affiliates. b) Business process
          auditors/advisers. c) Potential business stakeholders. This is for
          operational purposes or as mandated by law. 7. Third-party Sites Our
          policy doesn't govern third-party websites or services linked through
          our platform. Use them at your own discretion. 8. International
          Transfers Data may be transferred globally, ensuring protection levels
          similar to those in the EU. 9. Data Retention We store data as long as
          necessary for service provision and legal compliance. Generally, this
          doesn't exceed three years. 10. Your Rights You can: a) Access,
          modify, or delete your data. b) Limit data processing or transfer. c)
          Lodge complaints regarding data handling. Contact: info@zionpunks.com
          11. Security We prioritize data security with standard industry
          practices. However, we can't guarantee absolute security against
          unauthorized breaches. 12. Minors Our services aren't for those below
          13. Any data from such individuals, if identified, will be deleted.
        </Typography>
      </Box>
      <Box className={classes.wrapperText}>
        <Typography variant="body1">
          What Is Zion Punks ? This Is Aweb 3 Entrepreneur Initiative. The
          Project Is Links To Crypto Aid Groupe That Have A Committee Of
          Industry Leaders From Fireblocks, Marketacross, Collider Ventures,
          Cryptojungle, Ironblocks, Efficient Frontier, Nilos, Bits Of Gold,
          Allspot, Zengo, Israeli Blockchain Association And More. How Will The
          Funds Raised By Zion Punks Will Be Utilized? 100% Of The Funds Raised
          Will Provide Essential Humanitarian Aid For Victims Of Recent Attacks
          By Hamas On Israel, And To Help Them Rebuild Their Lives During These
          Challenging Times, Following The Horrendous Acts Of Terror That Have
          Deeply Affected Them. This Includes Residents Of The South Who Were
          Affected By Recent Attacks By Hamas, The Families Of Victims/missing
          Persons And The Families Of Soldiers And Militiamen. The Organization
          Staff Is Made Up Of More Than 300 Volunteers, None Of The Funding Goes
          To Administration Or Management. Lastly, The Companies Supporting The
          Custody & Offramp Are Working Pro Bono And Removing All Typical Fees.
          What Are Our Goals? The Funds Raised By Zion Punks Mint And Royalties
          Will Be Carefully Allocated To Provide Comprehensive Support Directly
          To Israeli Citizens Who Have Been Profoundly Affected By The War. This
          Support Will Encompass Various Critical Areas, Including: * Financial
          Relief: Direct Financial Assistance To Individuals And Families Who
          Have Suffered Economic Hardships As A Result Of The War. This Aid Can
          Help Cover Essential Expenses Such As Housing, Food, And Basic
          Necessities. * Medical Care: Ensuring Access To Necessary Medical
          Treatment And Healthcare Services For Those Injured Or In Need Of
          Medical Attention Due To The War. * Housing Rehabilitation: Assisting
          Those Who Have Lost Their Homes Or Experienced Damage To Their
          Properties By Facilitating Housing Rehabilitation And Reconstruction
          Efforts. * Community Rebuilding: Initiating Projects To Rebuild
          Affected Communities And Promote Resilience, Fostering A Sense Of
          Unity And Recovery Among Those Impacted By The War. These Funds Will
          Be Managed With Utmost Transparency And Accountability, Ensuring That
          They Are Used Effectively To Help Israeli Citizens Rebuild Their Lives
          And Communities In The Aftermath Of The War. Our Finance Committee
          Will Convene Daily And In The Spirit Of Our On-chain Ethos, We Will
          Provide Daily Transparency Reports To The Community. How Can I Make A
          Donation? By Minting Or Buying On 2nd Market How Is The Crypto Being
          Kept Secure? We Are Working With Multiple Industry Leaders Such As
          Fireblocks, Ironblocks And Nilos To Custody & Secure The Crypto. This
          Ensures Best Practices Of Mpc & Multisig From Respected Industry
          Leaders. Leading Banks And Government Agencies Are Assisting In The
          Safe Passage Of Funds To Israeli Bank Accounts. We Currently Have A
          Big 4 Auditing Firm Reviewing The Process And Will Provide Supervision
          To Project. What Is The Zion Punk Legal Entity? Zion Punks Are Linked
          To Crypto Aid. The Israeli Nonprofit Organization Is Called Blockchain
          B7. The Organization Is Regularly Audited By Its Accountants And
          Financial Reports Are Submitted To The Official Nonprofits Registry On
          An Annual Basis. For Official Documentation, Please Contact
          info@zionpunks.com
        </Typography>
      </Box>
    </Box>
  );
};

const useStyle = makeStyles(({ palette, breakpoints }) => ({
  root: {},
  header: {
    padding: "0 5rem",
    marginTop: "5rem",
    display: "flex",
    justifyContent: "flex-end",
  },
  wrapperLogo: {
    padding: "0 5rem",
    maxWidth: "40rem",
    marginTop: "2rem",
    "& img": {
      width: "100%",
    },
  },
  wrapperText: {
    padding: "0 5rem",
    marginTop: "7rem",
    marginBottom: "2rem",
  },
}));

export default Terms;
