import React from "react";

import { Buffer } from "buffer";

import "@rainbow-me/rainbowkit/styles.css";
import merge from "lodash.merge";

import {
  getDefaultWallets,
  RainbowKitProvider,
  midnightTheme,
} from "@rainbow-me/rainbowkit";

import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { infuraProvider } from "wagmi/providers/infura";

import { mainnet } from "wagmi/chains";
import Footer from "../../components/organisms/Footer/Footer";

window.Buffer = Buffer;

function App({ children }) {
  const projectId = process.env.REACT_APP_WALLETCONNECT_ID;

  const { chains, publicClient } = configureChains(
    [mainnet],
    [infuraProvider({ apiKey: process.env.REACT_APP_INFURA_ID })]
  );

  const { connectors } = getDefaultWallets({
    appName: "Zion Punks",
    projectId: projectId,
    chains,
  });

  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors,
    publicClient,
  });

  const myTheme = merge(midnightTheme(), {
    colors: {
      accentColor: "#0049FF",
      accentColorForeground: "#ffffff",
    },
    fonts: {
      body: "Montserrat, sans-serif",
    },
  });

  return (
    <>
      <WagmiConfig config={wagmiConfig}>
        <RainbowKitProvider chains={chains} theme={myTheme} locale="is">
          <main>{children}</main>
          <Footer />
        </RainbowKitProvider>
      </WagmiConfig>
    </>
  );
}

export default App;
