import React, { useEffect, useState } from "react";
import { ethers } from "ethers";
import Proptypes from "prop-types";
import { useContractWrite, useWaitForTransaction } from "wagmi";
import abi from "../../../abi/contract.json";
import { Box, Button, Typography, makeStyles } from "@material-ui/core";

const BtnMint = ({
  quantity,
  account,
  etherAmout,
  text,
  onConnect,
  disabled,
  checkMint,
}) => {
  const classes = useStyle();

  const {
    writeAsync: mint,
    error: mintError,
    data,
  } = useContractWrite({
    address: process.env.REACT_APP_CONTRACT_ADDRESS,
    abi,
    functionName: "mint",
  });

  const { isLoading, isSuccess } = useWaitForTransaction({
    hash: data?.hash,
  });

  useEffect(() => {
    checkMint();
  }, [isSuccess]);

  const [mintLoading, setMintLoading] = useState(false);

  const handleMint = async () => {
    if (!!account) {
      try {
        setMintLoading(true);
        await mint({
          args: [account, quantity],
          from: account,
          value: ethers.utils.parseEther(etherAmout),
        });
      } catch (error) {
        console.error(error);
      } finally {
        setMintLoading(false);
      }
    } else {
      onConnect();
    }
  };

  return (
    <Box className={classes.root}>
      <Button
        className={classes.btn}
        variant="contained"
        disabled={mintLoading || isLoading || disabled}
        onClick={handleMint}
      >
        {isLoading ? "Minting" : text}
      </Button>
      {!!mintError && (
        <Typography className={classes.error}>
          Error: {mintError?.details}
        </Typography>
      )}
    </Box>
  );
};

const useStyle = makeStyles(({ palette, breakpoints }) => ({
  root: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "2rem",
    flexDirection: "column",
  },
  btn: {
    background: palette.common.white,
    color: palette.primary.main,
  },
  error: {
    color: "#FF3333",
    padding: "0 3rem",
  },
}));

BtnMint.propTypes = {
  quantity: Proptypes.number,
  account: Proptypes.string,
  price: Proptypes.string,
  text: Proptypes.string,
  onConnect: Proptypes.func,
  disabled: Proptypes.bool,
  checkMint: Proptypes.func,
};

BtnMint.defaultProps = {
  quantity: 1,
  disabled: false,
};

export default BtnMint;
