import React from "react";
import { Route, Switch } from "react-router-dom";

import App from "./containers/App/App";
import Home from "./components/pages/Home/Home";
import Terms from "./components/pages/Terms/terms";

export default function AppRoutes(props) {
  return (
    <App {...props}>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/terms" component={Terms} />
        <Route component={Home} />
      </Switch>
    </App>
  );
}
