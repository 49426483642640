import React from "react";
import { Link as RouterLink } from "react-router-dom";

import { Typography, makeStyles, Link, Box } from "@material-ui/core";

const Footer = () => {
  const classes = useStyle();

  return (
    <footer className={classes.root}>
      <Box className={classes.wrapperFirstPart}>
        <Typography variant="body1">Zion Punks for Israel 💙</Typography>
        <Link
          component={RouterLink}
          to="/terms"
          variant="h5"
          underline="none"
          className={classes.link}
        >
          Terms
        </Link>
      </Box>
      <Box className={classes.wrapperSecondPart}>
        <Typography variant="body1">
          Directed by{" "}
          <a
            href="https://www.linkedin.com/in/jonathb/"
            target="_blank"
            rel="noreferrer"
          >
            Behar Jonathan
          </a>
        </Typography>
        <Typography variant="body1">
          Dev by{" "}
          <a
            href="https://www.linkedin.com/in/ayaleh/"
            target="_blank"
            rel="noreferrer"
          >
            Haddad Ayale
          </a>
        </Typography>

        <Typography variant="body1">
          Art by{" "}
          <a
            href="https://www.linkedin.com/in/charlottecohendesign/"
            target="_blank"
            rel="noreferrer"
          >
            Cohen Charlotte
          </a>{" "}
          &{" "}
          <a
            href="https://www.linkedin.com/in/jonathb/"
            target="_blank"
            rel="noreferrer"
          >
            Behar Jonathan
          </a>
        </Typography>
      </Box>
    </footer>
  );
};

const useStyle = makeStyles(({ palette, breakpoints }) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "1rem",
    flexDirection: "column",
    backgroundColor: "#f5f5f5",
    padding: "20px 0",
  },
  link: {
    color: "#272727",
    margin: 0,
    fontSize: "18px",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: 400,
    lineHeight: "1.8em",
    textDecoration: "underline",
  },
  wrapperFirstPart: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "1rem",
  },
  wrapperSecondPart: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    columnGap: "2rem",
    flexWrap: "wrap",
    "& a": {
      color: palette.primary.main,
    },
    "& p": {
      textAlign: "center",
    },
  },
}));

export default Footer;
