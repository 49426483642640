import React, { useState, useEffect, useMemo } from "react";
import cx from "classnames";
import { Avatar, Box, Button, Typography, makeStyles } from "@material-ui/core";
import Logo from "../../../assets/logo.png";
import VarietyPunks from "../../../assets/zion-punk-variety-2x.png";
import { ConnectButton, useConnectModal } from "@rainbow-me/rainbowkit";
import useMintVariables from "../../../hooks/useMintVariables";
import useSaleContract from "../../../hooks/useSaleContract";
import { useAccount } from "wagmi";
import { extractPrice, roundMint } from "../../../utils/mint";
import BtnMint from "../../molecules/BtnMint/BtnMint";
import useSupply from "../../../hooks/useSupply";
import twitter from "../../../assets/twitter.png";

import dlt from "../../../assets/images/dlt.png";
import shibolet from "../../../assets/images/shibolet.png";
import psagot from "../../../assets/images/psagot.png";

const Home = () => {
  const [choicePrice, setChoicePrice] = useState(0);

  const classes = useStyle();
  const saleContract = useSaleContract();

  const { address } = useAccount();
  const { openConnectModal } = useConnectModal();

  const [account, setAccount] = useState("");
  const [quantity, setQuantity] = useState(1);

  const { countMinted, maxPerWallet, textError, checkMint } = useMintVariables(
    saleContract,
    account
  );

  const { supplyMinted, updateTotalSupply } = useSupply(saleContract);

  const textBtnMint = useMemo(() => {
    if (account === undefined) return "Connect Wallet";

    return textError || "Mint";
  }, [account, textError]);

  const mintQuantityMax = useMemo(
    () => maxPerWallet - countMinted,
    [maxPerWallet, countMinted]
  );

  useEffect(() => {
    setAccount(address);
    checkMint();
  }, [address, checkMint]);

  useEffect(() => {
    updateTotalSupply();
    checkMint();
  }, [updateTotalSupply, checkMint]);

  const handleMoreQuantity = () => {
    if (quantity < mintQuantityMax) setQuantity(quantity + 1);
  };

  const handleLessQuantity = () => {
    if (quantity > 1) setQuantity(quantity - 1);
  };

  const handleChoicePrice = (choice) => () => {
    setChoicePrice(choice);
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <ConnectButton />
        <a href="https://x.com/zionpunks" target="_blank" rel="noreferrer">
          <Avatar src={twitter} alt="twitter" className={classes.media} />
        </a>
      </Box>
      <Box className={classes.wrapperLogo}>
        <img alt="logo" src={Logo} />
      </Box>
      <Box className={classes.wrapperText}>
        <Typography variant="body1" className={classes.text}>
          1,948 unique collectible characters inspired by{" "}
          <span className={classes.importantText}>
            iconic Israeli public figures
          </span>
          , each with its proof of ownership stored on the{" "}
          <a
            href="https://eips.ethereum.org/EIPS/eip-721"
            target="_blank"
            rel="noreferrer"
            className={classes.importantText}
          >
            Ethereum
          </a>{" "}
          blockchain. This project not only pays homage to the rich tapestry of
          Israel's history but also{" "}
          <span className={classes.importantText}>support a noble cause</span>.
          Every single cent from the minting, a full{" "}
          <span className={classes.importantText}>100%</span>, will be directed
          to associations that play pivotal roles in Israel's safety and
          well-being. Beneficiaries include{" "}
          <span className={classes.importantText}>Magen David Adom</span>,
          focused on emergency medical services and blood banks;
          <span className={classes.importantText}> ZAKA</span>, a humanitarian
          volunteer organization specialized in disaster victim identification;
          <span className={classes.importantText}> Atshala</span>, dedicated to
          ensuring children in need receive the educational support they
          require; and initiatives providing equipment and accommodations for
          the brave soldiers who safeguard the nation.
        </Typography>
        <Typography variant="h2">100% Given to Israel's Association</Typography>
        {!!supplyMinted && (
          <Typography variant="h3">
            {`Total Supply Minted ${supplyMinted} / 1948`}
          </Typography>
        )}
        {!!account && (
          <Typography variant="h3">
            {`You already minted ${countMinted} / ${maxPerWallet}`}
          </Typography>
        )}
      </Box>
      <Box className={cx(classes.wrapperText, classes.wrapperMint)}>
        <Box className={classes.numberOfMint}>
          <Button onClick={handleLessQuantity}>
            <span className={classes.btnNumber}>-</span>
          </Button>
          <Typography variant="body2" className={classes.quantityMint}>
            {quantity}
          </Typography>
          <Button onClick={handleMoreQuantity}>
            <span className={classes.btnNumber}>+</span>
          </Button>
        </Box>
        <Box className={classes.wrapperPrice}>
          <Typography variant="body1" className={classes.importantText}>
            Price per NFT
          </Typography>
          <Box className={classes.containerPrice}>
            <Button
              style={{ borderColor: choicePrice === 0 && "#0049FF" }}
              variant="outlined"
              onClick={handleChoicePrice(0)}
            >
              <span className={classes.btnPrice}>0.0770 ETH</span>
            </Button>
            <Button
              style={{ borderColor: choicePrice === 1 && "#0049FF" }}
              variant="outlined"
              onClick={handleChoicePrice(1)}
            >
              <span className={classes.btnPrice}>0.11948 ETH</span>
            </Button>
            <Button
              style={{ borderColor: choicePrice === 2 && "#0049FF" }}
              variant="outlined"
              onClick={handleChoicePrice(2)}
            >
              <span className={classes.btnPrice}>0.126 ETH</span>
            </Button>
            <Button
              style={{ borderColor: choicePrice === 3 && "#0049FF" }}
              variant="outlined"
              onClick={handleChoicePrice(3)}
            >
              <span className={classes.btnPrice}>0.25 ETH</span>
            </Button>
            <Button
              style={{ borderColor: choicePrice === 4 && "#0049FF" }}
              variant="outlined"
              onClick={handleChoicePrice(4)}
            >
              <span className={classes.btnPrice}>0.5 ETH</span>
            </Button>
            <Button
              style={{ borderColor: choicePrice === 5 && "#0049FF" }}
              variant="outlined"
              onClick={handleChoicePrice(5)}
            >
              <span className={classes.btnPrice}>1 ETH</span>
            </Button>
          </Box>
        </Box>
        <Box className={classes.resumeMint}>
          <Typography variant="body1" className={classes.importantText}>
            {`Total Donation: ${quantity} * ${extractPrice(
              choicePrice
            )} ETH = ${roundMint(quantity * extractPrice(choicePrice))}`}
          </Typography>
        </Box>
        <Box className={classes.wrapperBtnMint}>
          <BtnMint
            account={account}
            etherAmout={roundMint(
              quantity * extractPrice(choicePrice)
            ).toString()}
            quantity={quantity}
            text={textBtnMint}
            onConnect={openConnectModal}
            disabled={!!textError}
            checkMint={checkMint}
          />
        </Box>
        <Typography
          variant="body1"
          className={cx(classes.text, classes.small)}
          style={{ textAlign: "center" }}
        >
          26 ZionPunks have been pre-minted for major offchain donors
          <br />
          The Wallet that will receive all mint and Royalties is owned by Crypto
          Aid Israel team and managed by{" "}
          <span className={classes.importantText}>
            FireBlocks: 0x20325CcB35A756ad76b36653B500CC217c7551cC
          </span>
          <br />
          <br />
          <span className={classes.importantText}>
            100% of the MINT and the 20% Royalties
          </span>{" "}
          are also your <span className={classes.importantText}>Tsedaka</span>,
          which means "charity" in Hebrew, is a{" "}
          <span className={classes.importantText}>
            moral and religious obligation
          </span>{" "}
          in Judaism. However, tsédaka goes beyond mere charity, as it's seen as
          a form of justice, a way of balancing inequalities. Rambam
          (Maimonides) in his Mishneh Torah, Hilkhot Matnot Aniyim (Laws of
          Gifts to the Poor) 7:5, explains this concept:
          <br />
          <br />
          Giving less than 1/10 is "stingy".
          <br />
          Giving exactly 1/10 is "average".
          <br />
          <span className={classes.importantText}>
            Giving 1/5 is "generous"
          </span>{" "}
          and is the maximum recommended for most people.
          <br />
          Let's be Generous Zion Punks !
        </Typography>
      </Box>
      <Box className={classes.wrapperVarietyPunks}>
        <img alt="variety-punks" src={VarietyPunks} />
      </Box>
      <Box className={classes.wrapperText}>
        <Typography variant="body1" className={classes.text}>
          <span className={classes.importantText}>0.0770 ETH</span>, and patrons
          have the freedom to contribute more. To encourage broad participation,
          acquisition is capped at{" "}
          <span className={classes.importantText}>3 NFT Max per wallet</span>.
          Once all ZionPunks are minted, they can only be acquired through the
          embedded marketplace on the blockchain. This decentralized platform
          will enable enthusiasts to buy, bid on, and put their ZionPunks up for
          sale. To obtain a ZionPunk, follow the straightforward marketplace
          instructions. To stay abreast of all developments and engage with
          fellow enthusiasts, join our Discord Chat. An integrated bot will
          announce all bids, offers, and sales in real-time. ZionPunks: Where
          history, art, and philanthropy converge. Join us in making a
          difference.
        </Typography>
      </Box>
      <Box className={classes.wrapperLogos}>
        <Typography variant="h2">
          Zion PUNKS are affiliated to{" "}
          <a
            href="https://cryptoaidisrael.com"
            target="_blank"
            rel="noreferrer"
          >
            Crypto aid Israel
          </a>
        </Typography>
        <ul className={classes.logoList}>
          <li class="st visible">
            <img
              src="https://cryptoaidisrael.com/wp-content/themes/blankslate/images/logos/12.svg"
              alt=""
            />
          </li>
          <li class="st visible">
            <img
              src="https://cryptoaidisrael.com/wp-content/themes/blankslate/images/logos/1.png"
              alt=""
            />
          </li>
          <li class="st visible">
            <img
              src="https://cryptoaidisrael.com/wp-content/themes/blankslate/images/logos/2.png"
              alt=""
            />
          </li>
          <li class="st visible">
            <img
              src="https://cryptoaidisrael.com/wp-content/themes/blankslate/images/logos/4.png"
              alt=""
            />
          </li>
          <li class="st visible">
            <img
              src="https://cryptoaidisrael.com/wp-content/themes/blankslate/images/logos/5.png"
              alt=""
            />
          </li>
          <li class="st visible">
            <img
              src="https://cryptoaidisrael.com/wp-content/themes/blankslate/images/logos/6.png"
              alt=""
            />
          </li>
          <li class="st visible">
            <img
              src="https://cryptoaidisrael.com/wp-content/themes/blankslate/images/logos/7.png"
              alt=""
            />
          </li>
          <li class="st visible">
            <img
              src="https://cryptoaidisrael.com/wp-content/themes/blankslate/images/logos/8.png"
              alt=""
            />
          </li>
          <li class="st visible">
            <img
              src="https://cryptoaidisrael.com/wp-content/themes/blankslate/images/logos/9.png"
              alt=""
            />
          </li>
          <li class="st visible">
            <img
              src="https://cryptoaidisrael.com/wp-content/themes/blankslate/images/logos/10.png"
              alt=""
            />
          </li>
          <li class="st visible">
            <img
              src="https://cryptoaidisrael.com/wp-content/uploads/2023/10/1.png"
              alt=""
            />
          </li>
          <li class="st visible">
            <img
              src="https://cryptoaidisrael.com/wp-content/uploads/2023/10/2.png"
              alt=""
            />
          </li>
          <li class="st visible">
            <img
              src="https://cryptoaidisrael.com/wp-content/uploads/2023/10/3.png"
              alt=""
            />
          </li>
          <li class="st visible">
            <img
              src="https://cryptoaidisrael.com/wp-content/uploads/2023/10/4.png"
              alt=""
            />
          </li>
          <li class="st visible">
            <img
              src="https://cryptoaidisrael.com/wp-content/uploads/2023/10/5.png"
              alt=""
            />
          </li>
          <li class="st visible">
            <img
              src="https://cryptoaidisrael.com/wp-content/uploads/2023/10/6.png"
              alt=""
            />
          </li>
          <li class="st visible">
            <img src={dlt} alt="" />
          </li>
          <li class="st visible">
            <img
              src="https://cryptoaidisrael.com/wp-content/uploads/2023/10/5-1.png"
              alt=""
            />
          </li>
          <li class="st visible">
            <img
              src="https://cryptoaidisrael.com/wp-content/uploads/2023/10/6-1.png"
              alt=""
            />
          </li>
          <li class="st visible">
            <img
              src="https://cryptoaidisrael.com/wp-content/uploads/2023/10/7-1.png"
              alt=""
            />
          </li>
          <li class="st visible">
            <img
              src="https://cryptoaidisrael.com/wp-content/uploads/2023/10/8.png"
              alt=""
            />
          </li>
          <li class="st visible">
            <img
              src="https://cryptoaidisrael.com/wp-content/uploads/2023/10/13.png"
              alt=""
            />
          </li>
          <li class="st visible">
            <img
              src="https://cryptoaidisrael.com/wp-content/uploads/2023/10/14.png"
              alt=""
            />
          </li>
          <li class="st visible">
            <img
              src="https://cryptoaidisrael.com/wp-content/uploads/2023/10/15.png"
              alt=""
            />
          </li>
          <li class="st visible">
            <img
              src="https://cryptoaidisrael.com/wp-content/uploads/2023/10/16.png"
              alt=""
            />
          </li>
          <li class="st visible">
            <img src={shibolet} alt="" />
          </li>
          <li class="st visible">
            <img
              src="https://cryptoaidisrael.com/wp-content/uploads/2023/10/18.png"
              alt=""
            />
          </li>
          <li class="st visible">
            <img src={psagot} alt="" />
          </li>
          <li class="st visible">
            <img
              src="https://cryptoaidisrael.com/wp-content/uploads/2023/10/20.png"
              alt=""
            />
          </li>
          <li class="st visible">
            <img
              src="https://cryptoaidisrael.com/wp-content/uploads/2023/10/21.svg"
              alt=""
            />
          </li>
        </ul>
      </Box>
    </Box>
  );
};

const useStyle = makeStyles(({ palette, breakpoints }) => ({
  root: {},
  header: {
    padding: "0 5rem",
    marginTop: "5rem",
    display: "flex",
    justifyContent: "flex-end",
    gap: "1rem",
  },
  wrapperLogo: {
    padding: "0 5rem",
    maxWidth: "40rem",
    marginTop: "2rem",
    "& img": {
      width: "100%",
    },
  },
  media: {
    transition: "0.2s all ease-in-out",
    "&:hover": {
      opacitiy: 0.8,
    },
  },
  wrapperText: {
    padding: "0 5rem",
    marginTop: "7rem",
    marginBottom: "2rem",
    // maxWidth: "1170px",
    "& h2": {
      marginTop: "5rem",
      color: palette.primary.main,
      fontWeight: "bold",
      textTransform: "uppercase",
      fontSize: "3rem",
      textAlign: "center",
    },
    "& h3": {
      marginTop: "2rem",
      color: palette.primary.main,
      fontWeight: "bold",
      fontSize: "2.4rem",
      textAlign: "center",
    },
  },
  text: {
    "& a, span": {
      textDecoration: "none",
      color: palette.primary.main,
    },
  },
  small: {
    [breakpoints.down("xs")]: {
      fontSize: "1.4rem",
    },
    [breakpoints.down(400)]: {
      fontSize: "1.2rem",
    },
  },
  wrapperMint: {
    marginTop: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flexWrap: "wrap",
  },
  numberOfMint: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  btnNumber: {
    fontWeight: "bold",
    fontSize: "3rem",
    color: palette.primary.main,
  },
  quantityMint: {
    fontSize: "5rem",
    fontWeight: "bold",
    color: "#272727",
  },
  wrapperPrice: {
    marginTop: "2rem",
    display: "flex",
    gap: "2rem",
    alignItems: "center",
  },
  containerPrice: {
    [breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  btnPrice: {
    color: "#272727",
  },
  resumeMint: {
    marginTop: "2rem",
  },
  wrapperBtnMint: {
    margin: "2rem 0",
  },
  importantText: {
    color: palette.primary.main,
  },
  wrapperVarietyPunks: {
    "& img": {
      width: "100%",
    },
  },
  wrapperLogos: {
    padding: "2rem",
    "& h2": {
      marginTop: "2rem",
      color: palette.primary.main,
      fontWeight: "bold",
      textTransform: "uppercase",
      fontSize: "3rem",
      textAlign: "center",
      "& a": {
        color: palette.primary.main,
      },
    },
  },
  logoList: {
    display: "flex",
    margin: 0,
    padding: "40px 0 0",
    flexWrap: "wrap",
    gap: "24px",
    listStyle: "none",
    [breakpoints.down("sm")]: {
      gap: "1rem",
    },
    "& li": {
      display: "flex",
      width: "calc((100% - 120px) / 6)",
      aspectRatio: " 1/1",
      alignItems: "center",
      justifyContent: "center",
      background: "#0049FF",
      border: "1px solid #444",
      padding: " 16px",
      [breakpoints.down("md")]: {
        width: "calc((100% - 72px) / 4)",
      },
      [breakpoints.down("sm")]: {
        width: "calc((100% - 48px) / 3)",
      },
      [breakpoints.down("xs")]: {
        width: "calc((100% - 14px) / 2)",
        padding: "16px",
      },
      "& img": {
        maxWidth: "140px",
      },
    },
  },
}));

export default Home;
