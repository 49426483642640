export const extractPrice = (choicePrice) => {
  if (choicePrice === 0) return "0.077";
  if (choicePrice === 1) return "0.11948";
  if (choicePrice === 2) return "0.126";
  if (choicePrice === 3) return "0.25";
  if (choicePrice === 4) return "0.5";
  return "1";
};

export const roundMint = (number) => {
  return number.toFixed(3);
};
